// add the beginning of your app entry
import "./tw.css";
import "./header.css";
import "./content-single.css";
import "./content-overview.css";
import "./content-inline.css";
import "./sidebar-card-picks.css";

// Header left out of this as the view event occurs after the share of voice is chosen.
const plasticPlacements = [
	".plastic-content-inline",
	".plastic-content-overview",
	".plastic-content-single",
	".plastic-sidebar-card-picks",
	".credit-card-index-featured",
	".credit-card-index",
];

function impression(placement: string, label: string) {
	window.dataLayer.push({
		event: "viewed_card_promo",
		category: "credit_cards",
		placement: placement,
		label: label,
	});
}

// Function to handle DL elements
const observeDLElements = (parentElement: Element) => {
	const dlObserver = new MutationObserver((mutations) => {
		for (const mutation of mutations) {
			if (mutation.type === "attributes") {
				const dl = mutation.target as Element;
				const placement = dl.getAttribute("data-placement");
				const label = dl.getAttribute("data-label");

				if (placement && label) {
					impression(placement, label);
				}
			}
		}
	});

	// Find all DL elements and observe their attributes
	const dlElements = parentElement.querySelectorAll("dl");
	for (const dl of Array.from(dlElements)) {
		// Check initial state
		const placement = dl.getAttribute("data-placement");
		const label = dl.getAttribute("data-label");

		if (placement && label) {
			impression(placement, label);
		}

		// Observe for future changes
		dlObserver.observe(dl, {
			attributes: true,
			attributeFilter: ["data-placement", "data-label"],
		});
	}
};

// Main intersection observer
const observer = new IntersectionObserver(
	(entries) => {
		for (const entry of entries) {
			if (entry.isIntersecting) {
				const element = entry.target;

				// Check for direct article descendant
				const articles = element.querySelectorAll(":scope > article");
				if (articles.length) {
					for (const article of Array.from(articles)) {
						const placement = article.getAttribute("data-placement");
						const label = article.getAttribute("data-label");

						if (placement && label) {
							impression(placement, label);
						}
					}
				} else {
					// If no direct article, set up observers for DL elements
					observeDLElements(element);
				}

				// Unobserve the intersection after first view
				observer.unobserve(element);
			}
		}
	},
	{
		threshold: 0.5,
	},
);

//For floating CTA
if(document.querySelector('.floating-offer-bar')) {
	const offerBtn = document.querySelector('.offer-btn')
	const offerNav = document.querySelector('.offer-nav')
	const closeBtn = document.querySelector('.close-btn')

	if (offerBtn && offerNav && closeBtn) {

		// Show offer-nav when offer-btn is clicked, and hide offer-btn
		offerBtn.addEventListener('click', () => {
			offerNav.classList.add('flex'); // Show offer-nav
            offerNav.classList.remove('hidden');

			offerBtn.classList.add('hidden'); // Hide offer-btn

		});

		// Close offer-nav and show offer-btn when close button is clicked
		closeBtn.addEventListener('click', () => {
			offerNav.classList.add('hidden'); // Hide offer-nav
            offerNav.classList.remove('flex');

			offerBtn.classList.remove('hidden'); // Show offer-btn
		});
	}
}

if(document.querySelector('.cta-members-only')) {
	for (const button of Array.from(document.querySelectorAll('.cta-members-only'))) {
		button.addEventListener('click', (event: Event) => {
			event.preventDefault();

			const targetId = (button as HTMLAnchorElement).getAttribute('href');
			if (targetId?.startsWith('#')) {
				const targetElement = document.querySelector(targetId);
				if (targetElement) {
					targetElement.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				}
			}
		});
	}


}

// Observe each placement
for (const placement of plasticPlacements) {
	const elements = document.querySelectorAll(placement);
	for (const element of Array.from(elements)) {
		observer.observe(element);
	}
}
